import React from "react";

import portrait from "@assets/pics/portrait.jpg";
import "./styles.scss";

const Contact = () => {
  return (
    <main className="contact">
      <div className="container">
        <div className="texts">
          <p className="p">
            Please feel free to send feedback
            <br />
            or any other comments:
          </p>
          <p className="p">
            <a href="mailto:cm.anon.user+carthusian@gmail.com?subject=Carthusian%20Site">
              email
            </a>
          </p>
          <p className="p italic">Laudetur Iesus Christus</p>
        </div>

        <img src={portrait} alt="Pic" />
      </div>

      <div className="hr small-margin" />
    </main>
  );
};

export default Contact;
