import React from "react";
import useNavVisibility from "@hooks/useNavVisibility";

import logoImage from "@assets/misc/logo-old2.jpg";
import "./styles.scss";

const Navbar = () => {
  const navVisible = useNavVisibility(100);

  return (
    <nav className={`navbar ${navVisible}`}>
      <div className="nav-left">
        <img src={logoImage} alt="Pic" />
        <h2>Carthusian Site</h2>
      </div>
    </nav>
  );
};

export default Navbar;
