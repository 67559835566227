const sections1 = [
  {
    image: "pic0.jpg",
    texts: [
      '"My soul is thirsting for the strong and living God; when shall I come and see the face of God?" — Saint Bruno',
      "In 1084, the bishop of Grenoble led Bruno and some companions to a remote valley in the mountains, north of Grenoble, France. They established a community of hermits, dedicated to a life of prayer and solitude with God.",
      "Over the course of time, the small wooden dwellings were replaced by stronger, more permanent structures. Fires periodically caused much damage, which required rebuilding. By the late 17<sup>th</sup> century, the monastery reached its present size and form: a monastic church, surrounded by a cloister, with numerous cells, each for an individual monk.",
    ],
  },
  {
    image: "pic9.jpg",
    texts: [
      "Bruno was a theology professor from Cologne, who taught in Reims. For many years he desired to leave teaching in order to dedicate himself to seeking God. Instead of joining a monastery that follows the Rule of Saint Benedict, in the year 1084 he set out with some companions to start a new form of monastic life. The bishop of Grenoble, Hugh, led the men to a remote mountain valley which was ideal for their form of life. Each monk lived in a cell, as a hermit, but all reunited at certain times to celebrate the liturgy. In this way, Bruno established one of the first medieval communities of hermits.",
      "The architecture and design of the monastery allow the monks to live their unique form of life: partly in the solitude of the cell, partly in community with the other monks. This is how the Carthusians seek God: as a community of hermit monks.",
      "The monastery is situated at about 1,000 meters (3,280 feet) in a French national park. There are currently about 30 monks who live in the Grande Chartreuse. While the monastery is closed to the public, there is a small museum, <i>La Correrie</i>, which is open to visitors.",
    ],
  },
  {
    image: "pic1.jpg",
    texts: [
      "There is one Carthusian vocation, however there are two different paths: one for the <i>fathers</i>, the other for the <i>brothers</i>. Both lead to a life of solitude and contemplation, but a little differently.",
      "The <i>fathers</i> spend the vast majority of their time alone in the cell, where they live, work, and pray. They are responsible for celebrating all of the hours of the <i>Divine Office</i> (the monastic liturgy). On normal days, the fathers only leave their cells three times a day, to celebrate the liturgy together in the monastery church. All of the fathers study theology and are eventually ordained priests.",
      "The <i>brothers</i> have smaller cells and spend a large amount of time each day taking care of the community's needs. They are responsible for gardening, preparing food, mending clothes, repairing broken tools, cleaning, and just about everything else that needs to be done inside the monastery walls and on the grounds. Their activities and days are more varied than those of the fathers.",
    ],
  },
  {
    image: "pic2.jpg",
    texts: [
      "The cloister refers to the covered walkway that connects the cells and the rest of the monastery. The great cloister in the Grande Chartreuse is a total of 215 meters (705 feet) long.",
      "Monks use the cloister when they leave their cells to go to the monastic church or elsewhere in the monastery. As part of the monastery, it is a meditative place and silence is always observed, so that monks may continue their interior prayer. During the day, light streams in through windows; at night, it is shadowy and spiritual.",
      "The design of the Carthusian cloister has generally been quite simple in comparison to the more ornate medieval Benedictine and Cistercian ones. Carthusians value simplicity and sobriety, in their architecture as well in their monastic life.",
    ],
  },
  {
    image: "pic3.jpg",
    texts: [
      "The cell is where the priest monks spend most of their time. It is here that they pray, read, study, work, eat, and sleep. If a monk lives the Carthusian life well, he feels at home in his cell like a fish in water.",
      "Each cell is actually a small cottage composed of several small rooms: the cubiculum, the main dwelling, as well as others for work, storage of wood and tools, etc. Each cell also has a small garden, providing a place for both work and leisure during the warmer months.",
      "The monk spends the vast majority of his time alone in his cell. To many, this would be a very lonely life. However, the Carthusian finds that God is with him, dwelling in his cell. Thus the monk is never isolated or lonely. On the contrary, by living close to God in the cell, he finds a great spiritual fullness.",
    ],
  },
  {
    image: "pic5.jpg",
    texts: [
      "Work is an integral part of monastic life, which complements the many hours spent in prayer and liturgy. Even while he is busy working, a monk can maintain his inner prayer and closeness to God. Through his work, he glorifies God with his body, mind, and spirit.",
      "Each monk devotes some hours each day to work, depending on his age, health, abilities, and interests. Some of the work is manual (with the hands), other work can be more creative or intellectual.",
      "The Middle Ages have left us with many fine examples of the work of monks, such as beautifully illuminated manuscripts, as well as theological and spiritual treatises, among other things.",
    ],
  },
  {
    image: "pic6.jpg",
    texts: [
      "A Carthusian monastery is a community of solitaries. Although each monk spends most of the time in the solitude of the cell, the monks come together at certain times each day and each week.",
      "Every day, all the monks come together in the church for Matins and Lauds (night), the conventual Mass (morning), and Vespers (evening). On Sundays, most of the liturgical hours are celebrated in the church. After the midday prayer (Sext), the monks eat together in the refectory. One monk reads while the others eat. On Sunday afternoons, there is a time when the monks can meet in a group and talk with each other informally. Once a week, usually on Mondays, the monks go for a long walk outside of the monastery. Walking two by two, changing periodically, they have the chance to share their thoughts and experiences, as well as maintain friendship with the other monks.",
      "The monks see each other regularly, so there is no sense that one is living under the same roof with strangers. One would be surprised how well one gets to know the other monks in his community over time.",
    ],
  },
];

export default sections1;
