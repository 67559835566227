import React from "react";

import mapImage from "@assets/pics/map-view.jpg";
import earthImage from "@assets/pics/earth-view.jpg";
import monasteryImage from "@assets/pics/pic10.jpg";
import { location as texts } from "@constants/texts";

import "./styles.scss";

const Location = () => {
  const handleOpenLink = (e) => {
    e.preventDefault();

    const mapUrl =
      "https://www.google.com/maps/place/Monastery+of+the+Grande+Chartreuse/@45.4272125,5.4491136,9.77z/data=!4m5!3m4!1s0x478af8debbff49c1:0xffaefa02b6230ccc!8m2!3d45.3641647!4d5.7938939";
    window.open(mapUrl);
  };

  return (
    <main className="location">
      <h1 className="section-heading margin-bottom-very-small">Location</h1>

      <div className="image-row">
        <img
          src={mapImage}
          alt="Pic"
          className="pointer"
          onKeyDown={null}
          onClick={handleOpenLink}
        />
        <img src={earthImage} alt="Pic" />
        <img src={monasteryImage} alt="Pic" />
      </div>

      {texts.map((item, index) => (
        <p
          key={index}
          className="p"
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))}

      <div className="hr big-margin" />
    </main>
  );
};

export default Location;
