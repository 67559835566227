export const liturgy = [
  "The Carthusian monastic liturgy dates back to the founding of the order in the 11<sup>th</sup> century. Compared to the Benedictines and Cistercians, the Carthusians have a fairly simple and sober liturgy. No organ or any other musical instrument is ever used.",
  "Today, they have kept many traditional parts of the liturgy in Latin and Gregorian chant, while putting others in the vernacular (local language).",
  "The Carthusian Order has published many of their liturgical books at this <a href='http://www.chartreux.org/en/texts/liturgy.php' target='_blank'>website</a>.",
];

export const location = [
  "The Monastery of the Grande Chartreuse is located in a narrow mountain valley in the middle of the Chartreuse mountains, north of Grenoble, France.",
  "To the east of the monastery lies a mountain ridge, whose tallest peak is the <i>Grand Som</i> (2,026 meters / 6,647 feet).",
  "The surrounding mountains provide a peaceful atmosphere that is ideal for the cultivation of the inner life. In addition, the mountain landscape offers a wonderful environment for the weekly walks, which usually take place on Monday afternoon.",
];

export const bruno = {
  image: "st_bruno.jpg",
  texts: [
    '"<i>Stat crux dum volvitur orbis</i>: The Cross stands steady while the Earth turns."',
    '"Saint Bruno was good and simple. A man of profound heart."',
    '"<i>O Bonitas</i>: <i>O Goodness!</i>" — Saint Bruno',
  ],
};
