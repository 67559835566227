import React from "react";
import AudioPlayer from "react-h5-audio-player";

import liturgicalBook from "@assets/pics/pic4.jpg";
import responsorium1 from "@assets/misc/responsorium1.jpg";
import responsorium2 from "@assets/misc/responsorium2.jpg";
import gradualAudio from "@assets/media/gradual.mp3";
import { liturgy as texts } from "@constants/texts";

import "react-h5-audio-player/lib/styles.css";
import "./styles.scss";

const Liturgy = () => {
  return (
    <main className="liturgy">
      <h1 className="section-heading margin-bottom-small">Liturgy</h1>

      <div className="image-row">
        <img src={liturgicalBook} alt="Pic" />
      </div>

      {texts.map((item, index) => (
        <p
          key={index}
          className="p"
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))}

      <p className="p">Here is a response sung during the Mass in Holy Week:</p>

      <div className="image-row">
        <img src={responsorium1} alt="Pic" />
        <img src={responsorium2} alt="Pic" />
      </div>

      <AudioPlayer
        autoPlay={false}
        src={gradualAudio}
        onPlay={(e) => {}}
        style={{ borderRadius: 3, fontFamily: "Verdana, sans-serif" }}
      />

      <div className="big-margin" />
    </main>
  );
};

export default Liturgy;
