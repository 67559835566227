const sections2 = [
  {
    image: "pic7.jpg",
    texts: [
      "Silence, solitude, prayer. Alone with God.",
      'Saint Paul wrote, "For you died to this life and your life is hidden with Christ in God" (<i>Col 3:3</i>).',
      "The Carthusian monk experiences this hidden life with God.",
    ],
  },
  {
    image: "pic8.jpg",
    texts: [
      "What is it really like to be a Carthusian monk? Do they regularly have mystical visions and get lost in contemplation?",
      "It is easy to romanticize the monastic life, to think that it is ethereal, that monks have left behind all of the cares and concerns of this world. In reality, monks remain human and struggle with all of the same shortcomings as ordinary people.",
      "Of course, the monks who persevere living the Carthusian monastic life tend to change and grow, personally as well as spiritually, over time. It is indeed possible that, during the course of many years and decades, a monk achieves a higher level of sanctity, but it is not guaranteed. In the end, everything is due to the grace of God.",
    ],
  },
];

export default sections2;
