import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "@components/Navbar";
import Main from "@components/Main";
import "../styles/master.scss";

const IndexPage = () => {
  const currentYear = new Date().getFullYear();

  return (
    <main className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Carthusian Site | A Visit to the Monastery of the Grande Chartreuse
        </title>
        <link rel="canonical" href="https://www.carthusian.site/" />
      </Helmet>

      <Navbar />
      <h1 className="top-heading">A Visit to the Grande Chartreuse</h1>
      <Main />
      <div className="footer">
        Copyright &copy; 2018-{currentYear}, A former Carthusian.
      </div>
    </main>
  );
};

export default IndexPage;
