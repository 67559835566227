import React from "react";

import Section from "@components/Section";
import Location from "@components/Location";
import Liturgy from "@components/Liturgy";
import Contact from "@components/Contact";

import sections1 from "@constants/sections1";
import sections2 from "@constants/sections2";
import { bruno as brunoText } from "@constants/texts";

const Main = () => {
  return (
    <main>
      {sections1.map((item, index) => (
        <Section key={`1_${index}`} index={`1_${index}`} data={item} />
      ))}

      <Location />
      <Liturgy />

      {sections2.map((item, index) => (
        <Section key={`2_${index}`} index={`2_${index}`} data={item} />
      ))}

      <Section index="3_0" data={brunoText} />
      <Contact />
    </main>
  );
};

export default Main;
