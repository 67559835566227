import React from "react";
import "./styles.scss";

const Section = ({ index, data }) => {
  const { image, texts } = data;
  const src = require(`@assets/pics/${image}`);

  return (
    <main className="section">
      {index === "1_0" && (
        <>
          <h1 className="section-heading">Overview</h1>
          <div className="hr small-margin" />
        </>
      )}

      {index === "2_0" && (
        <>
          <h1 className="section-heading">Final</h1>
          <div className="hr small-margin" />
        </>
      )}

      <img src={src.default} alt="Pic" />
      {texts.map((item, index) => (
        <p
          key={index}
          className="p"
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))}
      <div className="hr big-margin" />
    </main>
  );
};

export default Section;
