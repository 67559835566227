import { useState, useEffect } from "react";

const useNavVisibility = (threshold) => {
  /*
   * Returns:
   *  - "" as initial value
   *  - "visible", if scrolled below threshold
   *  - "invisible", if scrolled from below to above threshold
   */
  const [visibility, setVisibility] = useState("");

  useEffect(() => {
    let pageYOffset = window.scrollY;
    let previousOffset = 0;
    let tick = false;

    const updateScroll = () => {
      pageYOffset = window.scrollY;

      if (pageYOffset > threshold) {
        previousOffset = pageYOffset;
        setVisibility("visible");
        tick = false;
        return;
      } else if (previousOffset > threshold && pageYOffset < threshold) {
        setVisibility("invisible");
        tick = false;
      } else {
        tick = false;
      }
    };

    const onScroll = () => {
      if (!tick) {
        window.requestAnimationFrame(updateScroll);
        tick = true;
      }
    };

    window.addEventListener("scroll", onScroll, true);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return visibility;
};

export default useNavVisibility;
